import { keyframes } from "@chakra-ui/react"

const shiftRainbowColors = keyframes`
  0% { background-position: calc(200px / 0.707) 0; }
`

const fillModifier = {
  fontSize: 18,
  lineHeight: "26px",
  color: "primary.white",
  fill: "brand.white",
  bgColor: "brand.darkBlue",
  minW: "100px",
  h: "auto",
  border: "2px solid transparent",
  borderRadius: "34px",
  px: "22px",
  py: "12px",
  transition: "all .3s ease-in-out",
  _active: {
    bgColor: "brand.offWhite",
  },
  _hover: {
    color: "brand.darkBlue",
    fill: "brand.darkBlue",
    bgColor: "brand.offWhite",
    borderColor: "brand.darkBlue",
    textDecoration: "none",
  },
}

const fillSmallModifier = {
  ...fillModifier,
  fontSize: 16,
  lineHeight: "24px",
  py: "9.5px",
}

const fillSmallHoverFlatModifier = {
  ...fillSmallModifier,
  _hover: {
    ...fillModifier._hover,
    color: "brand.darkBlue",
    bgColor: "brand.offWhite",
    borderColor: "brand.offWhite",
  },
}

const outlineModifier = {
  ...fillModifier,
  color: "brand.darkBlue",
  bgColor: "primary.white",
  border: "2px solid",
  borderColor: "brand.darkBlue",
  _hover: {
    color: "primary.white",
    bgColor: "brand.darkBlue",
  },
  _active: {
    color: "primary.white",
    bgColor: "brand.darkBlue",
  },
}

const headerItemModifier = {
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  color: "brand.black",
  bgColor: "transparent",
  h: "100%",
  px: { base: "20px", xl: "24px" },
  py: "16px",
  _hover: {
    textDecoration: "none",
    bgColor: "preset.default",
    color: "primary.white",
  },
}

const headerMegaItemLevel1Modifier = {
  fontSize: 14,
  lineHeight: "24px",
  textAlign: "center",
  fontWeight: "700",
  whiteSpace: "normal",
  color: "primary.white",
  h: "auto",
  borderRadius: "25px",
  py: "6px",
  px: "12px",
  display: "block",
  _after: {
    content: `" "`,
    d: "block",
    borderBottom: "2px solid",
    borderBottomColor: "primary.white",
    transition: "width 0.3s ease",
    w: 0,
  },
  _hover: {
    _after: {
      w: "full",
    },
  },
}

export const Button = {
  baseStyle: {
    fontFamily: "Larsseit",
    fontFeatureSettings: '"ss01" on, "ss02" on',
    fontSize: 10,
    fontWeight: 400,
    borderRadius: 0,
    _disabled: {
      opacity: 0.4,
      pointerEvents: "none",
    },
  },

  sizes: {
    small: {
      fontSize: 16,
      lineHeight: "24px",
      py: "9.5px",
    },
    medium: {},
    large: {},
  },
  variants: {
    fill: fillModifier,
    fillRainbowLoader: {
      fontSize: 18,
      lineHeight: "26px",
      color: "primary.white",
      fill: "brand.white",
      bgColor: "brand.darkBlue",
      minW: "100px",
      h: "auto",
      borderRadius: "34px",
      px: "22px",
      py: "12px",
      transition: "all .3s ease-in-out",
      border: "none",
      "@media (hover: hover) and (pointer: fine)": {
        _hover: {
          background: "repeating-linear-gradient(45deg, #ffae61, #eaac3e, #a5e065, #2bd2c9, #77a5ff, #f9423a, #ffae61 200px)",
          backgroundSize: "calc(200px / 0.7) 100%",
          animation: `${shiftRainbowColors} 2s linear infinite reverse`,
        },
      },
      _active: {
        background: "repeating-linear-gradient(45deg, #ffae61, #eaac3e, #a5e065, #2bd2c9, #77a5ff, #f9423a, #ffae61 200px)",
        backgroundSize: "calc(200px / 0.7) 100%",
        animation: `${shiftRainbowColors} 2s linear infinite reverse`,
      },
      _loading: {
        background: "repeating-linear-gradient(45deg, #ffae61, #eaac3e, #a5e065, #2bd2c9, #77a5ff, #f9423a, #ffae61 200px)",
        backgroundSize: "calc(200px / 0.7) 100%",
        animation: `${shiftRainbowColors} 2s linear infinite reverse`,
      },
      _disabled: {
        opacity: 0.4,
        color: "brand.white",
        bgColor: "brand.darkBlue",
      },
    },
    // DEPRECATED JUST USE FILL AND CHANGE THE FONTSIZE
    fillSmall: fillSmallModifier,

    // ONLY SEEMS TO BE USED FOR THE CONTACT FLEXIBLE
    fillIcon: {
      ...fillModifier,
      fontSize: { base: 18, sm: 20 },
      lineHeight: 1.5,
    },
    fillWhite: {
      ...fillModifier,
      color: "brand.darkBlue",
      bgColor: "primary.white",
      _hover: {
        color: "primary.white",
        bgColor: "brand.darkBlue",
      },
    },
    fillGradient: {
      ...fillModifier,
      h: "48px",
      _active: { bgGradient: "linear-gradient(90deg, #47BCFF 0%, #23CDAE 53.47%, #90ABFE 100%)", textDecoration: "none", border: 0 },
      _hover: {
        bgGradient: "linear-gradient(90deg, #47BCFF 0%, #23CDAE 53.47%, #90ABFE 100%)",
        textDecoration: "none",
        border: 0,
      },
    },
    outline: outlineModifier,

    headerItem: {
      ...headerItemModifier,
      _hover: {
        ...headerItemModifier._hover,
      },
      _after: {
        pos: "absolute",
        content: '""',
        right: "18px",
        height: "6px",
        width: "6px",
        top: "50%",
        mt: "1px",
        transform: "translateY(-50%)",
        bgColor: "brand.red",
        borderRadius: "50%",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
      },
    },
    headerMegaItemLevel1: headerMegaItemLevel1Modifier,
    headerMegaItemLevel1Active: {
      ...headerMegaItemLevel1Modifier,
      color: "preset.default",
    },

    headerMegaItemLevel2: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "700",
      padding: 0,
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
      },
    },

    headerIcon: {
      color: "brand.darkBlue",
      minWidth: 0,
      transition: "all .4s ease-in-out",
      borderRadius: "4px",
      _focusVisible: {
        outline: "#77A5FF solid 1px",
        bgColor: "#E9E9EA",
        boxShadow: "0px 0px 5px 3px #77A5FF95 !important",
      },
      _hover: {
        color: "primary.greyDisabled",
      },
      _active: {
        outline: "none",
        bgColor: "none",
        boxShadow: "none",
      },
    },
    accountDetails: {
      ...fillSmallHoverFlatModifier,
      fontSize: "18px",
      lineHeight: "24px",
      px: "20px",
      py: "7px",
      minW: "unset",
    },

    cart: {
      h: "unset",
      minH: "unset",
      minW: "unset",
      w: "unset",
      lineHeight: 0,
      p: 2,
      color: "brand.black",
      _disabled: {
        color: "brand.grayDisabled",
        opacity: 1,
      },
      _hover: {
        color: "brand.grayText",
      },
    },
    dropdown: {
      ...outlineModifier,
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 13.3333,
      lineHeight: "normal",
      textAlign: "left",
      borderRadius: "21.6px",
      transition: "none",
    },

    quizRectangle: {
      h: { base: "66px", sm: "100px" },
      w: "100%",
      maxW: { base: "334px", sm: "345px" },
      border: "1px solid #1C1F2A",
      backgroundColor: "primary.white",
      _active: {
        background: "primary.lime",
      },
      _hover: {
        base: { backgroundColor: "" },
        sm: { backgroundColor: "primary.lime" },
      },
    },
    quizCircle: {
      h: { base: "120px", sm: "130px" },
      w: { base: "120px", sm: "130px" },
      borderRadius: "50%",
      border: "1px solid #1C1F2A",
      backgroundColor: "primary.white",
      whiteSpace: "break-spaces",
      _active: {
        backgroundColor: "primary.lime",
      },
      _hover: {
        base: { backgroundColor: "" },
        sm: { backgroundColor: "primary.lime" },
      },
    },
    wishlistShare: {
      display: "flex",
      gap: 2,
      p: 4,
      m: 0,
      w: "full",
      alignItems: "center",
      justifyContent: "flex-start",
      _hover: {
        backgroundColor: "primary.greyLight",
      },
    },
    transparent: {
      minWidth: 0,
      h: "unset",
      p: 0,
    },
  },
  defaultProps: {
    variant: "transparent",
  },
}
