import React, { useCallback, useEffect, useRef, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { useCart } from "@app/hooks/useCart"
import Icon from "@app/components/Icon"
import MotionBox from "@app/components/MotionBox"
import { CartList } from "@app/components/Cart/CartList"
import { CartSummary } from "@app/components/Cart/CartSummary"
import { CartCheckout } from "@app/components/Cart/CartCheckout"
import { CartGiftWrap } from "@app/components/Cart/CartGiftWrap"
import { CartProgress } from "@app/components/Cart/CartProgress"
import { CartThreshold } from "@app/components/Cart/CartThreshold"
import { GiftWithPurchase } from "@app/components/Cart/GiftWithPurchase/GiftWithPurchase"
import { CartMiniOrderWarning } from "@app/components/Cart/CartMiniOrderWarning"

export const CartMini: React.FC = React.memo(() => {
  const { count, cart } = useCartContext()
  const { state, dispatch } = useAppContext()
  const { giftWrapping, isBelowMinOrder, hasOnlyGiftCards, removeFromCart } = useCart()
  const [cartError, setCartError] = useState("")

  const listRef = useRef()

  const onClose = useCallback(() => {
    setCartError("")
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state.activeCart])

  // if cartLines has quantity of 0, remove it from the cart and set an error state
  useEffect(() => {
    const cartLines = cart?.lines
    const cartLinestWithoutQuantity = cartLines?.filter(item => item.quantity === 0)

    if (cartLinestWithoutQuantity?.length) {
      setCartError("Some items in your cart are out of stock and were removed.")
      cartLinestWithoutQuantity?.forEach(item => {
        removeFromCart(item.id, item.merchandise.id)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.lines])

  return (
    <>
      <AnimatePresence>
        {state.activeCart && (
          <MotionBox
            bgColor="primary.white"
            pos="fixed"
            top={0}
            zIndex={1100}
            h="100vh"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            w="100%"
            maxH="-webkit-fill-available"
            alignItems="stretch"
            left="unset"
            maxW={{ base: "90vw", sm: 160 }}
            right={0}
            minHeight="calc(100vh - calc(100vh - 100%))"
            transition={{ duration: 0.5 }}
            initial={{ right: "-100%" }}
            animate={{ right: 0 }}
            exit={{ right: "-100%" }}
          >
            <Box flexShrink={0}>
              <Flex pos="relative" alignItems="center" justifyContent="space-between" minH="60px">
                <Flex
                  as="button"
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  pl={[4, 6]}
                  py="10px"
                  onClick={onClose}
                  w={1 / 3}
                >
                  <Icon name="chevronLeft" width="10px" height="auto" />
                </Flex>
                <Heading as="p" variant="heading26" textAlign="center" fontWeight={500} w={1 / 3}>
                  Bag
                </Heading>
                <Flex flexDirection="column" alignItems="flex-end" justifyContent="center" py="10px" pr={[4, 6]} w={1 / 3}>
                  <MotionBox
                    borderRadius="100%"
                    w={7}
                    h={7}
                    color="white"
                    textAlign="center"
                    initial={{
                      backgroundColor: "#000",
                      transition: {
                        duration: 1,
                      },
                    }}
                    whileInView={{
                      backgroundColor: "#000",
                      transition: {
                        duration: 1,
                      },
                    }}
                    whileHover={{
                      backgroundColor: ["#f9423a", "#ffae61", "#a5e065", "#2bd2c9", "#77a5ff", "#bdd6e6", "#f9423a"],
                      transition: {
                        duration: 2.5,
                        repeat: Infinity,
                        repeatType: "loop",
                      },
                    }}
                  >
                    <Text fontSize="sm" pt={1.5}>
                      {count}
                    </Text>
                  </MotionBox>
                </Flex>
              </Flex>
              <CartProgress cartMiniProgress={100} />
            </Box>
            {count > 0 && isBelowMinOrder ? <CartMiniOrderWarning /> : null}
            <Flex ref={listRef} flex="1" flexDir="column" overflowY="auto" overflowX="hidden">
              <Box pos="relative" flex={1}>
                <CartThreshold />
                <GiftWithPurchase />
                {cartError && (
                  <Center px={5} py={4}>
                    <Text color="red.500">{cartError}</Text>
                  </Center>
                )}
                <Box></Box>
                <CartList drawer />
              </Box>
            </Flex>
            {count > 0 ? (
              <Box flexShrink={0}>
                {!hasOnlyGiftCards && giftWrapping?.enabled && <CartGiftWrap listRef={listRef} />}
                <CartSummary drawer />
                <CartCheckout isDisabled={isBelowMinOrder} />
              </Box>
            ) : null}
          </MotionBox>
        )}
      </AnimatePresence>
      <Box
        as="button"
        bgColor="brand.darkBlue"
        pos="fixed"
        top={0}
        left={0}
        w="100%"
        h="100%"
        zIndex={900}
        transition="opacity .3s ease-in-out,visibility .3s ease-in-out"
        userSelect="none"
        sx={{ WebkitTapHighlightColor: "transparent" }}
        visibility={state.activeCart ? "visible" : "hidden"}
        opacity={state.activeCart ? 0.4 : 0}
        onClick={onClose}
      />
    </>
  )
})
